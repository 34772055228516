<script setup lang="ts">
import type { TranslateResult } from "vue-i18n";
import type { RouteLocationRaw } from "vue-router";
import { mdiArrowRight } from "@mdi/js";

const props = withDefaults(
  defineProps<{
    cardTitle?: string | TranslateResult;
    cardDescription?: string | TranslateResult;
    imagePath?: string;
    imageColor?: string;
    to?: RouteLocationRaw;
    externalLink?: boolean;
    imageClasses?: string[];
    imageBackgroundClasses?: string[];
    cardRowClasses?: string[];
    cardClasses?: string[];
  }>(),
  {
    cardDescription: "",
    cardTitle: "",
    imagePath: "",
    externalLink: false,
    imageColor: "rgba(var(--v-theme-primary-lighten-2))",
    imageClasses: () => ["hw-hexagon"],
    cardClasses: () => [],
    imageBackgroundClasses: () => [],
    cardRowClasses: () => [],
  },
);

const { isActive } = useActiveRoute(props.to);

const cardClassesComputed = computed(() => {
  return isActive.value
    ? ["mobile-navigation__card--active", ...props.cardClasses]
    : [...props.cardClasses];
});

import { useEventBus } from "@vueuse/core";
import { actionMenuKey, MenuType } from "../../types/menuActionKey";
const actionMenuBus = useEventBus(actionMenuKey);
</script>

<template>
  <v-card
    color="#FFFFFF"
    class="hw-mx mobile-navigation__card"
    :to="to"
    @click="actionMenuBus.emit({ menuType: MenuType.Mobile })"
    :class="cardClassesComputed"
  >
    <v-row
      class="d-flex align-center mobile-navigation__card__row"
      :class="cardRowClasses"
    >
      <slot name="default" />

      <v-col
        cols="3"
        class="d-none d-sm-flex justify-center align-center pa-0"
        :class="imageBackgroundClasses"
      >
        <slot name="image">
          <HwImage
            :src="imagePath"
            :class="imageClasses"
            style="
              padding: var(--spacer);
              margin: var(--spacer);
              width: 8rem;
              max-width: 90%;
              max-height: 90%;
              object-fit: contain;
            "
            :style="`background: ${imageColor}`"
          />
        </slot>
      </v-col>

      <v-col
        class="d-flex flex-column justify-center mobile-navigation__card__text hw-ml-small hw-mr pa-0"
      >
        <slot name="card-text">
          <div class="text-h3">
            {{ cardTitle }}
          </div>

          {{ cardDescription }}

          <v-icon
            :icon="[mdiArrowRight]"
            class="navigation__card__description__icon"
            :class="{
              'icon--external-link ': externalLink,
            }"
          />
        </slot>
      </v-col>
    </v-row>
  </v-card>
</template>
